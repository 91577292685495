<script setup>
const props = defineProps({
  href: [Object,String],
  target: String,
  isMoto: {
      type: Boolean,
      default: false
    }
},
)
</script>

<template>
  <NuxtLink
    :to="props.href"
    :target="target"
    :class="isMoto ? 'xl:flex' : 'md:flex'"
    class="font-extralight text-sm hover:text-azure cursor-pointer transition-colors hidden justify-center items-center whitespace-nowrap"
  >
      <slot />
  </NuxtLink>
</template>
